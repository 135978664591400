<template>
    <b-card header-tag="header" class="shadow m-sm-5 p-sm-4">
        <template #header>
            <h5 class="mb-0">{{$route.name}}</h5>
        </template>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
              <template v-for="(m, index) in model">
                <form-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
              </template>
              <div class="mb-3"> 
                <label class="form-label">Provinsi <span class="text-danger">*</span></label>
                <b-form-select v-model="provinsi.selected" :options="provinsi.options" @change="getKota(provinsi.selected)"></b-form-select>
              </div>
              <div class="mb-3">
                <label class="form-label">Kota <span class="text-danger">*</span></label>
                <b-form-select v-model="kota.selected" :options="kota.options" @change="getKecamatan(kota.selected)"></b-form-select>
              </div>
              <div class="mb-3">
                <label class="form-label">Kecamatan <span class="text-danger">*</span></label>
                <b-form-select v-model="kecamatan.selected" :options="kecamatan.options" @change="getKelurahan(kecamatan.selected)"></b-form-select>
              </div>
              <div class="mb-3">
                <label class="form-label">Kelurahan <span class="text-danger">*</span></label>
                <b-form-select v-model="kelurahan.selected" :options="kelurahan.options"></b-form-select>
              </div>

              <div>
                <b-button :href="'#/master-data/mitra'" variant="warning" class="mt-3 text-white float-right"> Batal</b-button>
                <b-button type="submit" variant="primary" class="mt-3 float-left"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
              </div>
            </form>
        </ValidationObserver>
      </b-card>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import Mitra from '@/models/Mitra.js'
import Provinsi from '@/models/Provincy.js'
import Kota from '@/models/Regency.js'
import Kecamatan from '@/models/District.js'
import Kelurahan from '@/models/Village.js'

const MitraModel = new Mitra()
const ProvinsiModel = new Provinsi()
const KotaModel = new Kota()
const KecamatanModel = new Kecamatan()
const KelurahanModel = new Kelurahan()

export default {
  components: {
    formGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (this.mode === 'update') {
        this.getDetail()
      }
    }
  },
  computed: {
    mode: function () {
      return this.$route.params.id !== undefined ? 'update' : 'create'
    }
  },
  data () {
    return {
      isLoading: false,
      provinsi: {
        selected: null,
        options: [
          { value: null, text: 'Pilih Provinsi' },
        ]
      },
      kota: {
        selected: null,
        options: [
          { value: null, text: 'Pilih Kota' },
        ]
      },
      kecamatan: {
        selected: null,
        options: [
          { value: null, text: 'Pilih Kecamatan' },
        ]
      },
      kelurahan: {
        selected: null,
        options: [
          { value: null, text: 'Pilih Kelurahan' },
        ]
      },
      model: MitraModel.init()
    }
  },
  mounted () {
    this.getProvinsi()
    if (this.mode === 'update') {
      this.getDetail()
    }
  },
  methods: {
    getOptions (key) {
      return typeof this.model[key].options !== 'undefined' ? objectToOptions(this.model[key].options) : null
    },
    getDetail () {
      MitraModel.find(this.$route.params.id).then(resp => {
        setModelValue(this.model, resp)
        Object.keys(this.model).forEach(key => {
          this.$refs[key][0].setValue(this.model[key].value)
        })
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
      })
    },
    resetForm () {
      this.$nextTick(() => {
        // clearModelValue(this.model)
        Object.keys(this.model).forEach(key => {
          this.$refs[key][0].setValue(null)
        })
        this.$refs.form.reset()
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        this.$refs['province_id'][0].setValue(this.provinsi.selected)
        this.$refs['regency_id'][0].setValue(this.kota.selected)
        this.$refs['district_id'][0].setValue(this.kecamatan.selected)
        this.$refs['village_id'][0].setValue(this.kelurahan.selected)
        this.$refs['is_active'][0].setValue('1')
        saveProcess(this.model, this.mode === 'update').then(model => {
          this.isLoading = true
          if (this.mode === 'update') {
            MitraModel.update(this.$route.params.id, model).then(() => {
              this.isLoading = false
              this.$store.dispatch('notification/success', 'Data Mitra berhasil diubah.')
              this.$router.push('/master-data/mitra/' + this.$route.params.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          } else {
            
            MitraModel.create(model).then(resp => {
              this.isLoading = false
              // this.resetForm()
              this.$store.dispatch('notification/success', 'Data Mitra berhasil disimpan.')
              this.$router.push('/master-data/mitra/' + resp.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          }
        }).catch(() => {})
      })
    },

    // location handle func
    getProvinsi() {
      this.$http.get(ProvinsiModel.endpoint + '?limit=100').then(resp => {
        this.provinsi.options = [
          { value: null, text: 'Pilih Provinsi' },
        ]
        resp.data.data.forEach(d => {
          this.provinsi.options.push({ value: d.id, text: d.name })
        })
      })
    },
    getKota(provinsi_id) {
      this.$http.get(KotaModel.endpoint + '?province_id=' + provinsi_id + '&limit=100').then(resp => {
        this.kota.options = [
          { value: null, text: 'Pilih Kota' },
        ]
        resp.data.data.forEach(d => {
          this.kota.options.push({ value: d.id, text: d.name })
        })
      })
    },
    getKecamatan(kota_id) {
      this.$http.get(KecamatanModel.endpoint + '?regency_id=' + kota_id + '&limit=100').then(resp => {
        this.kecamatan.options = [
          { value: null, text: 'Pilih Kecamatan' },
        ]
        resp.data.data.forEach(d => {
          this.kecamatan.options.push({ value: d.id, text: d.name })
        })
      })
    },
    getKelurahan(kecamatan_id) {
      this.$http.get(KelurahanModel.endpoint + '?district_id=' + kecamatan_id + '&limit=100').then(resp => {
        this.kelurahan.options = [
          { value: null, text: 'Pilih Kelurahan' },
        ]
        resp.data.data.forEach(d => {
          this.kelurahan.options.push({ value: d.id, text: d.name })
        })
      })
    },
  }
}
</script>